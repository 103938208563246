define("discourse/plugins/discourse-assign/discourse/components/assign-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assignPost = assignPost;
  _exports.default = void 0;
  _exports.unassignPost = unassignPost;
  class AssignButton extends _component.default {
    static shouldRender(args) {
      return !args.post.firstPost;
    }
    static hidden(args) {
      return args.post.assigned_to_user?.id !== args.state.currentUser.id;
    }
    static #_ = (() => dt7948.g(this.prototype, "taskActions", [_service.service]))();
    #taskActions = (() => (dt7948.i(this, "taskActions"), void 0))();
    get icon() {
      return this.isAssigned ? "user-xmark" : "user-plus";
    }
    get isAssigned() {
      return this.args.post.assigned_to_user || this.args.post.assigned_to_group;
    }
    get title() {
      return this.isAssigned ? "discourse_assign.unassign_post.title" : "discourse_assign.assign_post.title";
    }
    acceptAnswer() {
      if (this.isAssigned) {
        unassignPost(this.args.post, this.taskActions);
      } else {
        assignPost(this.args.post, this.taskActions);
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "acceptAnswer", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class={{if
            this.isAssigned
            "post-action-menu__unassign-post unassign-post"
            "post-action-menu__assign-post assign-post"
          }}
          ...attributes
          @action={{this.acceptAnswer}}
          @icon={{this.icon}}
          @title={{this.title}}
        />
      
    */
    {
      "id": "seoWQ4b+",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,0,[52,[30,0,[\"isAssigned\"]],\"post-action-menu__unassign-post unassign-post\",\"post-action-menu__assign-post assign-post\"]],[17,1]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"acceptAnswer\"]],[30,0,[\"icon\"]],[30,0,[\"title\"]]]],null],[1,\"\\n  \"]],[\"&attrs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-assign/discourse/components/assign-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  // TODO (glimmer-post-menu): Remove these exported functions and move the code into the button action after the widget code is removed
  _exports.default = AssignButton;
  function assignPost(post, taskActions) {
    taskActions.showAssignModal(post, {
      isAssigned: false,
      targetType: "Post"
    });
  }
  async function unassignPost(post, taskActions) {
    await taskActions.unassign(post.id, "Post");
    delete post.topic.indirectly_assigned_to[post.id];
  }
});