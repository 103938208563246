define("discourse/plugins/discourse-assign/discourse/components/assign-actions-dropdown", ["exports", "@ember/object", "@ember-decorators/component", "discourse-i18n", "select-kit/components/dropdown-select-box", "select-kit/components/select-kit"], function (_exports, _object, _component, _discourseI18n, _dropdownSelectBox, _selectKit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AssignActionsDropdown = dt7948.c(class AssignActionsDropdown extends _dropdownSelectBox.default {
    headerIcon = null;
    allowInitialValueMutation = false;
    showFullTitle = true;
    computeContent() {
      let options = [];
      if (this.assignee) {
        options = options.concat([{
          id: "unassign",
          icon: this.group ? "group-times" : "user-xmark",
          name: (0, _discourseI18n.i18n)("discourse_assign.unassign.title"),
          description: (0, _discourseI18n.i18n)("discourse_assign.unassign.help", {
            username: this.assignee
          })
        }, {
          id: "reassign",
          icon: "users",
          name: (0, _discourseI18n.i18n)("discourse_assign.reassign.title"),
          description: (0, _discourseI18n.i18n)("discourse_assign.reassign.help")
        }]);
      }
      if (this.topic.indirectly_assigned_to) {
        Object.entries(this.topic.indirectly_assigned_to).forEach(entry => {
          const [postId, assignment_map] = entry;
          const assignee = assignment_map.assigned_to;
          options = options.concat({
            id: `unassign_post_${postId}`,
            icon: assignee.username ? "user-xmark" : "group-times",
            name: (0, _discourseI18n.i18n)("discourse_assign.unassign_post.title"),
            description: (0, _discourseI18n.i18n)("discourse_assign.unassign_post.help", {
              username: assignee.username || assignee.name
            })
          });
        });
      }
      return options;
    }
    onChange(id) {
      switch (id) {
        case "unassign":
          this.unassign(this.topic.id);
          break;
        case "reassign":
          this.reassign(this.topic, this.assignee);
          break;
      }
      const postId = id.match(/unassign_post_(\d+)/)?.[1];
      if (postId) {
        this.unassign(postId, "Post");
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "onChange", [_object.action]))();
  }, [(0, _component.classNames)("assign-actions-dropdown"), (0, _selectKit.selectKitOptions)({
    icon: null,
    translatedNone: "...",
    showFullTitle: true
  })]);
  var _default = _exports.default = AssignActionsDropdown;
});