define("discourse/plugins/discourse-assign/discourse/components/topic-assignments", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse-i18n", "select-kit/components/combo-box", "discourse/plugins/discourse-assign/discourse/components/assignment", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _discourseI18n, _comboBox, _assignment, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicAssignments extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "selectedAssignment", [_tracking.tracked], function () {
      return this.args.assignments.find(a => a.id === 0);
    }))();
    #selectedAssignment = (() => (dt7948.i(this, "selectedAssignment"), void 0))();
    get assignmentOptions() {
      return this.args.assignments.map(a => this.#toComboBoxOption(a));
    }
    selectAssignment(id) {
      this.selectedAssignment = this.args.assignments.find(a => a.id === id);
    }
    static #_2 = (() => dt7948.n(this.prototype, "selectAssignment", [_object.action]))();
    #toComboBoxOption(assignment) {
      const option = {
        id: assignment.id
      };
      if (assignment.targetType === "Topic") {
        option.name = (0, _discourseI18n.i18n)("edit_assignments_modal.topic");
      } else {
        option.name = `${(0, _discourseI18n.i18n)("edit_assignments_modal.post")} #${assignment.postNumber}`;
      }
      return option;
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="control-group target">
          <label>{{i18n "discourse_assign.assign_modal.assignment_label"}}</label>
          <ComboBox
            @value={{this.selectedAssignment.id}}
            @content={{this.assignmentOptions}}
            @onChange={{this.selectAssignment}}
          />
        </div>
        <Assignment
          @assignment={{this.selectedAssignment}}
          @onSubmit={{@onSubmit}}
          @showValidationErrors={{false}}
        />
      
    */
    {
      "id": "q8K6z1Hy",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"control-group target\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,[28,[32,0],[\"discourse_assign.assign_modal.assignment_label\"],null]],[13],[1,\"\\n      \"],[8,[32,1],null,[[\"@value\",\"@content\",\"@onChange\"],[[30,0,[\"selectedAssignment\",\"id\"]],[30,0,[\"assignmentOptions\"]],[30,0,[\"selectAssignment\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[32,2],null,[[\"@assignment\",\"@onSubmit\",\"@showValidationErrors\"],[[30,0,[\"selectedAssignment\"]],[30,1],false]],null],[1,\"\\n  \"]],[\"@onSubmit\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-assign/discourse/components/topic-assignments.js",
      "scope": () => [_discourseI18n.i18n, _comboBox.default, _assignment.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopicAssignments;
});